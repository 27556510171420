import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DialogResult } from '@equityeng/e2g-ng-ui';
import { faAngleDown, faArrowLeft, faCalculator } from '@fortawesome/pro-solid-svg-icons';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BreadcrumbsService } from 'src/app/breadcrumb-module/breadcrumbs.service';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { CommentTypes } from 'src/app/models/enums/comment-types';
import { SaveChangesDialog } from 'src/app/shared-module/models/save-changes-dialog';
import { AssetFeature, GeneralFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { AssetDetailsNavService } from 'src/app/shared-module/services/asset-details-nav.service';
import { AssetRouteData, RouteParamsService } from 'src/app/shared-module/services/route-params.service';

import { AttachmentCacheService } from '../../attachments-module/attachment-cache.service';
import { EquipmentDataService } from '../../equipment-data.service';
import { AssetTypes } from '../../models/enums/asset-types';
import { EquipmentDto } from '../../models/equipment-dto';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { ISaveChanges } from '../../save-changes';
import { DialogService } from '../../shared-module/dialog.service';

//TODO Move into folder models/enums/tabs?
export enum AssetDetailTabs {
  Asset = 1,
  Components = 2,
  Groups = 3,
  Cmls = 4,
  Activities = 5,
  Findings = 6,
  Comments = 7,
  Configuration = 8,
  Rbi = 9
}

//TODO Move into folder models/enums/tabs/asset-details-sub-tabs?
export enum ActivitiesTabs {
  Upcoming = 1,
  History = 2
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent extends OnDestroyBaseComponent implements OnInit, OnDestroy, ISaveChanges {
  @ViewChildren(ISaveChanges) public saveComponents!: QueryList<ISaveChanges>;

  public commentType?: CommentTypes = CommentTypes.Equipment;
  public activeTabId: AssetDetailTabs = AssetDetailTabs.Asset;
  public equipment: EquipmentDto | undefined;
  public inputData!: AssetRouteData;
  public assetName: string = '';
  public breadcrumbs: Array<Breadcrumb> = [];
  public assetDetailTabs = AssetDetailTabs;
  public prdRbiEnabled: boolean = false;

  public faArrowLeft = faArrowLeft;
  public faCalculator = faCalculator;
  public faAngleDown = faAngleDown;
  public commentsReadonly = true;

  public constructor(
    private permissionService: PermissionService,
    private readonly routeParamsService: RouteParamsService,
    private readonly navService: AssetDetailsNavService,
    private equipService: EquipmentDataService,
    private dialogService: DialogService,
    private attachmentCache: AttachmentCacheService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.routeParamsService
      .getAssetRouteData()
      .pipe(
        tap((assetRouteData) => {
          this.inputData = assetRouteData;
          if (assetRouteData.activeTabId !== undefined) {
            this.activeTabId = assetRouteData.activeTabId!;
          }
        }),
        filter(() => this.inputData.equipmentKey != this.equipment?.id),
        //Reload asset page if navigating from one asset to another
        tap(() => (this.equipment = undefined)),
        switchMap(() =>
          this.permissionService.getPermissions({
            asset: [{ feature: AssetFeature.Asset, assetId: this.inputData.equipmentKey! }],
            general: [{ feature: GeneralFeature.PrdRbi }]
          })
        ),
        tap((permission) => {
          this.commentsReadonly = !permission.asset[AssetFeature.Asset].edit;
          this.prdRbiEnabled = permission.general[GeneralFeature.PrdRbi];
        }),
        switchMap(() =>
          forkJoin([
            this.equipService.getSingleEquipment(this.inputData.equipmentKey),
            this.breadcrumbsService.getAssetBreadcrumbs(this.inputData.equipmentKey)
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([equipment, breadcrumbs]) => {
        this.breadcrumbs = breadcrumbs;
        this.equipment = equipment;

        this.assetName = this.equipment.name;
      });
  }

  public updateRoute(tabId: number): void {
    this.activeTabId = tabId;
    this.navService.resetAssetTab(this.inputData, tabId);
  }

  public ngOnDestroy(): void {
    this.attachmentCache.clearCache();
    super.ngOnDestroy();
  }

  public onBeforeSwitching(event: NgbNavChangeEvent): void {
    if (this.isDirty()) {
      event.preventDefault();
      this.dialogService
        .display({
          ...SaveChangesDialog,
          validityCallback: (): boolean => this.canSaveChanges()
        })
        .pipe(
          switchMap((result) => {
            if (result === DialogResult.yes) {
              return this.saveChanges().pipe(tap(() => (this.activeTabId = event.nextId)));
            } else if (result === DialogResult.no) {
              this.revertChanges();
              this.updateRoute(event.nextId);
            }
            return of(false);
          }),
          takeUntil(this.destroy)
        )
        .subscribe();
    } else {
      this.updateRoute(event.nextId);
    }
  }

  public isDirty(): boolean {
    return this.saveComponents ? this.saveComponents.filter((x) => x.isDirty() === true).length > 0 : false;
  }

  public canSaveChanges(): boolean {
    return this.saveComponents
      ? this.saveComponents.filter((x) => !!x.canSaveChanges && !x.canSaveChanges()).length == 0
      : true;
  }

  public saveChanges(): Observable<boolean> {
    if (this.saveComponents) {
      return this.saveComponents.first.saveChanges();
    }

    return of(false);
  }

  public revertChanges(): void {
    if (this.saveComponents) {
      this.saveComponents.first.revertChanges();
    }
  }

  public isPrd(): boolean {
    return this.equipment?.type == AssetTypes.PressureReliefDevice;
  }

  public isPrdConfigurationVisible(): boolean {
    return this.isPrd() && this.prdRbiEnabled;
  }

  public isPrdRbiVisible(): boolean {
    return this.isPrdConfigurationVisible() && this.equipment!.rbi!;
  }

  public isAssetTypeOther(): boolean {
    return this.equipment?.type == AssetTypes.Other;
  }

  public updateTab(newActiveTab: number): void {
    if (!this.isPrd() || newActiveTab in [1, 5, 6, 7]) {
      this.updateRoute(newActiveTab);
    }
  }
}
