import { Injectable } from '@angular/core';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class JointAssemblyProcedureReportService {
  public constructor(
    private jointAssemblyProcedureReportDataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(sageModuleId: string, ijThermalModel: IntellijointThermalModel): void {
    const notificationName = 'Joint Assembly Procedure Report';
    this.notificationService.showActionStarting(ActionTypes.Generate, notificationName, sageModuleId);

    this.jointAssemblyProcedureReportDataService
      .generateJointAssemblyProcedureReport(sageModuleId, ijThermalModel)
      .subscribe((success) =>
        success
          ? this.jobNotificationsService.refreshNotifications()
          : this.notificationService.showActionResult(false, ActionTypes.Generate, notificationName, sageModuleId)
      );
  }
}

export enum IntellijointThermalModel {
  FEA = 1,
  WRC510 = 2
}
