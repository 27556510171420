import { inject, TemplateRef } from '@angular/core';
import { ICurrentUserFluidInfo, INameValue } from '@equityeng/sage-forms-core';
import { Subject, distinctUntilChanged, switchMap } from 'rxjs';
import { FluidStreamDataService } from './fluid-data.service';
import { UnitDataService } from './unit-data.service';

export class CurrentUserFluidInfo implements ICurrentUserFluidInfo {
  private fluidService = inject(FluidStreamDataService);
  private unitService = inject(UnitDataService);

  private streams: Array<INameValue<string>> = [];
  private groups: Array<INameValue<string>> = [];
  private unitId$ = new Subject<string>();

  public constructor() {
    this.fluidService.getFluidStreams().subscribe((x) => {
      x.forEach((y) => {
        this.streams.push({ value: y.id!, label: y.name!, description: '' });
      });
    });

    this.unitId$
      .pipe(
        distinctUntilChanged(),
        switchMap((unitId) => this.unitService.getInventoryGroups(unitId))
      )
      .subscribe((x) => {
        x.forEach((y) => {
          this.groups.push({ value: y.id!, label: y.name!, description: '' });
        });
        this.groups.push({ value: 'Unassigned', label: 'Unassigned', description: '' });
      });
  }

  private fluidRef!: TemplateRef<any>;

  public setUnitId(unitId: string): void {
    this.unitId$.next(unitId);
  }
  public setFluidTemplate(ref: TemplateRef<any>): void {
    this.fluidRef = ref;
  }
  public getFluidTemplate(): TemplateRef<any> {
    return this.fluidRef;
  }
  public getFluidList(): Array<INameValue<string>> {
    return this.streams;
  }

  public getInventoryGroupList(): Array<INameValue<string>> {
    return this.groups;
  }
}
