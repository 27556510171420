import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CalcUpdateDto, PlantManagerImpactedIdsDto } from '../models/calculation-impacted-ids-dto';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  public updates: Observable<PlantManagerImpactedIdsDto> = new Subject<PlantManagerImpactedIdsDto>();
  public calcUpdates: Observable<CalcUpdateDto> = new Subject<CalcUpdateDto>();
  //Modify when more than just calculations triggering updates
  public triggerDataUpdate(calcUpdate: CalcUpdateDto): void {
    (this.calcUpdates as Subject<CalcUpdateDto>).next(calcUpdate);
    if (calcUpdate.impactedIds) {
      (this.updates as Subject<PlantManagerImpactedIdsDto>).next(calcUpdate.impactedIds);
    }
  }
}
