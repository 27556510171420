<div [ngClass]="getSlideoutHeaderBorder() | async">
  <div class="slide-out-header">
    <div class="d-flex flex-nowrap align-items-center justify-content-between h-100">
      <div class="d-flex flex-nowrap align-items-center">
        <ng-container *ngIf="headerVisible | async; else headingTemplate">
          <fa-icon
            *ngIf="backButtonVisible | async"
            id="icon-back-arrow"
            class="icon-secondary"
            [icon]="faArrowLeft"
            size="xl"
            (click)="onCancel()"
          ></fa-icon>

          <e2g-text-input
            *ngIf="editing && (allowRename | async); else displayTemplate"
            id="inp-name"
            class="header-name"
            tablindex="1"
            [(ngModel)]="editName"
            [required]="true"
            [readonly]="false"
            (input)="editingNameChange()"
            [errors]="nameErrors"
            (change)="editingNameChange()"
          >
          </e2g-text-input>

          <ng-template #displayTemplate>
            <div *ngIf="dirty || new; else optionSelectTemplate" class="header-name" id="readonly-heading">
              {{ origName }}
            </div>
          </ng-template>
          <ng-template #optionSelectTemplate>
            <e2g-ng-select-input
              id="sel-inp-name"
              class="header-name"
              tablindex="1"
              placeholder="Select Name"
              [options]="options"
              [(ngModel)]="selectedOptionId"
              [required]="true"
              (change)="onSelectionChange()"
            >
            </e2g-ng-select-input>
          </ng-template>

          <ng-container *ngIf="allowRename | async">
            <fa-icon
              *ngIf="!editing"
              id="icon-edit-name"
              class="icon-secondary"
              size="lg"
              [icon]="faPenToSquare"
              (click)="enterNameEdit()"
            ></fa-icon>

            <fa-icon
              *ngIf="editing && origName.length > 0"
              id="icon-cancel-name-editing"
              class="icon-secondary"
              size="lg"
              [icon]="faX"
              (click)="cancelNameEdit()"
            ></fa-icon>
          </ng-container>
          <ng-container *ngIf="!dirty && !new">
            <fa-icon
              *ngIf="!editing"
              id="icon-left-arrow"
              class="icon-secondary"
              [icon]="faArrowLeft"
              size="lg"
              (click)="back()"
            ></fa-icon>
            <fa-icon
              *ngIf="!editing"
              id="icon-right-arrow"
              class="icon-secondary"
              size="lg"
              [icon]="faArrowRight"
              (click)="next()"
            ></fa-icon>
          </ng-container>
          <div
            *ngIf="calcError && calcError.length > 0"
            class="calculationErrors pointer ms-4"
            (click)="displayCalculationErrors()"
          >
            <fa-icon class="exclamationTriangle fa-xl" [icon]="faExclamationTriangle" [fixedWidth]="true"> </fa-icon>
            <label class="errorLabel pointer ms-2">View Calculation Errors</label>
          </div>
        </ng-container>
      </div>
      <fa-icon id="icon-cancel" class="icon-secondary" size="lg" [icon]="faX" (click)="onCancel()"></fa-icon>
    </div>
  </div>
  <ng-template #headingTemplate>
    <div class="d-flex flex-row align-items-center pt-2">
      <fa-icon
        *ngIf="backButtonVisible | async"
        id="icon-back-arrow"
        class="icon-secondary"
        [icon]="faArrowLeft"
        size="xl"
        (click)="onCancel()"
      ></fa-icon>
      <div id="id-static-heading">{{ staticHeading | async }}</div>
    </div>
  </ng-template>
  <div class="breadcrumbs" *ngIf="breadcrumbs.length > 0">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs" [disableLinks]="dirty"></app-breadcrumbs>
  </div>
</div>

<ng-template #calcErrorsTemplate>
  <div class="overflow-auto">
    <pre [innerHTML]="parseMsg(calcError!)"></pre>
  </div>
</ng-template>
