<div
  id="toast-container"
  class="d-flex flex-row align-items-center"
  [style.background-color]="toast.displayProperties.color"
>
  <div *ngIf="toast.displayProperties.showSpinner" class="spinner-icon mr-2"></div>
  <fa-icon class="mr-2" *ngIf="toast.displayProperties.icon" [icon]="toast.displayProperties.icon" size="lg"></fa-icon>
  <div class="mr-2">{{ toast.message }}</div>
  <fa-icon
      *ngIf="toast.displayProperties.closable ?? true"
      id="close"
      class="icon-secondary"
      size="xs"
      [icon]="faX"
      (click)="close()"
  ></fa-icon>
</div>
