import { Injectable } from '@angular/core';
import { AuthService, User } from '@equityeng/auth';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { concatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/company.service';
import {
  ADMIN,
  CAN_DELETE_ACTIVITY,
  CAN_DELETE_CIRCUIT,
  CAN_DELETE_CML,
  CAN_DELETE_COMPONENT,
  CAN_DELETE_DAMAGEMONITORINGLOCATION,
  CAN_DELETE_EQUIPMENT,
  CAN_DELETE_FINDINGS,
  CAN_EDIT_ACTIVITY,
  CAN_EDIT_CIRCUIT,
  CAN_EDIT_CML,
  CAN_EDIT_COMPONENT,
  CAN_EDIT_DAMAGEMONITORINGLOCATION,
  CAN_EDIT_EQUIPMENT,
  CAN_EDIT_FINDINGS,
  CAN_FORCE_CALC,
  CAN_MANAGE_ROLES,
  CAN_VIEW_DIAGS,
  COMPANY_ADMIN,
  DML,
  IDMS,
  PRDRBI,
  PRODUCT_NAME,
  RBI,
  RBI_EXPERT_VIEW,
  SAGE,
  SCRA_RATES
} from 'src/app/models/auth-constants';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { enumToValueArray } from 'src/app/utilities/enum-helper';

import { HierarchyPermissionDataService } from './hierarchy-permission-data.service';
import {
  AssetFeature,
  AssetFeatureQuery,
  AssetPermissionModel,
  GeneralFeature,
  GeneralFeatureQuery,
  NO_PERMISSION
} from './permission-models';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public userData: Observable<User> = new ReplaySubject<User>(1);
  private assetIdPermissions: Map<string, boolean> = new Map<string, boolean>();
  private assetTypePermissions?: Array<AssetTypes>;
  private useHierarchyPermissions!: boolean;

  public constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    private dataService: HierarchyPermissionDataService
  ) {
    this.companyService.selectedCompany
      .pipe(
        switchMap(() => this.authService.userAuthenticated$),
        concatMap((user) =>
          this.dataService.isTenantUsingHierarchyPermissions().pipe(
            tap((x) => (this.useHierarchyPermissions = x)),
            map(() => user)
          )
        )
      )
      .subscribe((user) => {
        this.assetIdPermissions.clear();
        this.assetTypePermissions = undefined;
        (this.userData as ReplaySubject<User>).next(user);
      });
  }

  public getPermissions(input: { asset?: Array<AssetFeatureQuery>; general?: Array<GeneralFeatureQuery> }): Observable<{
    asset: Record<AssetFeature, AssetPermissionModel>;
    general: Record<GeneralFeature, boolean>;
  }> {
    return this.userData.pipe(
      switchMap((user) =>
        this.createAssetFeatureRecord(user, input.asset).pipe(
          map((assetFeatures) => {
            return {
              asset: assetFeatures,
              general: this.createGeneralFeatureRecord(user, input.general)
            };
          })
        )
      ),
      take(1)
    );
  }

  public getAssetPermissionModel(assetFeature: AssetFeature, assetId: string): Observable<AssetPermissionModel> {
    return this.userData.pipe(switchMap((user) => this.getAssetPermission(user, { feature: assetFeature, assetId })));
  }

  public hasGeneralFeature(generalFeature: GeneralFeature): Observable<boolean> {
    return this.userData.pipe(map((user) => this.getPermissionToGeneralFeature(user, { feature: generalFeature })));
  }

  public getAllowedAssetTypeList(): Observable<Array<AssetTypes>> {
    if (this.assetTypePermissions) {
      return of(this.assetTypePermissions);
    } else if (!this.useHierarchyPermissions) {
      this.assetTypePermissions = enumToValueArray(AssetTypes);
      return of(this.assetTypePermissions);
    }
    return this.dataService.getAllowedAssetTypeList().pipe(tap((x) => (this.assetTypePermissions = x)));
  }

  private getAssetPermission(user: User, query: AssetFeatureQuery): Observable<AssetPermissionModel> {
    const model = this.getAssetPermissionsForType(user, query.feature);

    //Only make the api call if we need to
    if (model.edit || model.delete) {
      return this.hasAccessToAssetId(query.assetId).pipe(map((x) => (x ? model : NO_PERMISSION)));
    }
    return of(model);
  }

  private getPermissionToGeneralFeature(user: User, query: GeneralFeatureQuery): boolean {
    switch (query.feature) {
      case GeneralFeature.Admin:
        return user.hasAccessToFeature(PRODUCT_NAME, ADMIN);
      case GeneralFeature.Rbi:
        return user.hasAccessToProduct(RBI);
      case GeneralFeature.PrdRbi:
        return user.hasAccessToProduct(PRDRBI);
      case GeneralFeature.Dmls:
        return user.hasAccessToProduct(DML);
      case GeneralFeature.ScraRates:
        return user.hasAccessToProduct(SCRA_RATES);
      case GeneralFeature.ForceCalculation:
        return user.hasPermission(CAN_FORCE_CALC);
      case GeneralFeature.SageDiagnostics:
        return user.hasPermission(CAN_VIEW_DIAGS);
      case GeneralFeature.ManageRoles:
        //Admins always can manage roles so they can't accidentally unassign themselves the abilty to change roles
        return user.hasPermission(CAN_MANAGE_ROLES) || user.hasAccessToFeature(PRODUCT_NAME, COMPANY_ADMIN);
      case GeneralFeature.AssignHierarchyAdmins:
        return user.hasPermission(CAN_MANAGE_ROLES);
      case GeneralFeature.ViewHierarchyPermissions:
        return this.useHierarchyPermissions;
      case GeneralFeature.SAGE:
        return user.hasAccessToProduct(SAGE);
      case GeneralFeature.RbiExpertView:
        return user.hasAccessToProduct(RBI_EXPERT_VIEW);
      case GeneralFeature.Idms:
        return user.hasAccessToProduct(IDMS);
    }

    throw 'Unknown General Feature';
  }

  private getAssetPermissionsForType(user: User, accessItem: AssetFeature): AssetPermissionModel {
    switch (accessItem) {
      case AssetFeature.Asset:
        return this.makeAssetPermissionModel(user, CAN_EDIT_EQUIPMENT, CAN_DELETE_EQUIPMENT);
      case AssetFeature.Component:
        return this.makeAssetPermissionModel(user, CAN_EDIT_COMPONENT, CAN_DELETE_COMPONENT);
      case AssetFeature.Cml:
        return this.makeAssetPermissionModel(user, CAN_EDIT_CML, CAN_DELETE_CML);
      case AssetFeature.Circuit:
        return this.makeAssetPermissionModel(user, CAN_EDIT_CIRCUIT, CAN_DELETE_CIRCUIT);
      case AssetFeature.Activity:
        return this.makeAssetPermissionModel(user, CAN_EDIT_ACTIVITY, CAN_DELETE_ACTIVITY);
      case AssetFeature.Finding:
        return this.makeAssetPermissionModel(user, CAN_EDIT_FINDINGS, CAN_DELETE_FINDINGS);
      case AssetFeature.Dml:
        return this.getPermissionToGeneralFeature(user, { feature: GeneralFeature.Dmls })
          ? this.makeAssetPermissionModel(user, CAN_EDIT_DAMAGEMONITORINGLOCATION, CAN_DELETE_DAMAGEMONITORINGLOCATION)
          : NO_PERMISSION;
      case AssetFeature.Comments:
        return {
          edit: true,
          delete: true
        };
    }
  }

  private makeAssetPermissionModel(user: User, editPermission: string, deletePermission: string): AssetPermissionModel {
    return {
      edit: user.hasPermission(editPermission),
      delete: user.hasPermission(deletePermission)
    };
  }

  private hasAccessToAssetId(assetId: string): Observable<boolean> {
    if (!this.useHierarchyPermissions) {
      return of(true);
    }

    if (this.assetIdPermissions.has(assetId)) {
      return of(this.assetIdPermissions.get(assetId)!);
    }

    return this.dataService.hasAccessToAsset(assetId).pipe(tap((x) => this.assetIdPermissions.set(assetId, x)));
  }

  private createAssetFeatureRecord(
    user: User,
    requests?: Array<AssetFeatureQuery>
  ): Observable<Record<AssetFeature, AssetPermissionModel>> {
    if (requests) {
      return forkJoin(requests.map((x) => this.getAssetPermission(user, x).pipe(take(1)))).pipe(
        map((assetFeatures) =>
          assetFeatures.reduce((p, c, i) => {
            p[requests![i].feature] = c;
            return p;
          }, {} as Record<AssetFeature, AssetPermissionModel>)
        )
      );
    }
    return of({} as Record<AssetFeature, AssetPermissionModel>);
  }

  private createGeneralFeatureRecord(
    user: User,
    requests?: Array<GeneralFeatureQuery>
  ): Record<GeneralFeature, boolean> {
    return (requests ?? []).reduce((p, c) => {
      p[c.feature] = this.getPermissionToGeneralFeature(user, c);
      return p;
    }, {} as Record<GeneralFeature, boolean>);
  }
}
