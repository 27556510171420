<ng-container *ngIf="notifications | async as localNotifications; else loading">
  <div class="d-flex flex-column h-100" clickOutside (clickOutside)="onOutsideClick($event)">
    <div class="alert notifications-header mb-0 d-flex justify-content-between align-items-center">
      <div><h2 class="mb-0">Notifications</h2></div>
      <div>
        <button
          type="button"
          class="btn btn-sm e2g-btn-outline-primary"
          (click)="onDeleteAll()"
          [disabled]="!canDeleteAll(localNotifications)"
          id="btn-clear"
        >
          Clear All
        </button>
      </div>
    </div>
    <div style="overflow-y: auto">
      <ng-container *ngIf="localNotifications.length > 0; else noNotifications">
        <div id="div-notifications" class="list-group-flush">
          <ng-container *ngFor="let item of localNotifications; trackBy: trackNotification">
            <div class="notification-item">
              <div class="d-flex justify-content-between w-100 p-1">
                <div class="d-flex flex-nowrap align-items-center">
                  <div class="fa-fw me-2 ms-1">
                    <ng-container *ngIf="!newOrInProgress(item.status); else processingJob">
                      <fa-icon
                        [icon]="getIcon(item)"
                        size="lg"
                        [ngClass]="getIconClass(item)"
                      ></fa-icon>
                    </ng-container>
                  </div>
                  <div class="d-flex flex-col fw-bold me-1">
                    <ng-container *ngIf="!item.moduleId || !item.moduleName; else moduleLink" >
                      {{ item.name }}
                    </ng-container>
                    <ng-template #moduleLink>
                      <div class="d-flex flex-row">
                        {{ moduleStatusText(item.status) }}
                        <div class="ms-1 e2g-link" (click)="goToLink(item)">
                          {{ item.moduleName }}
                        </div>
                      </div>
                    </ng-template>
                    <div class="d-flex flex-row small fw-normal">
                      {{ item.creationTime | date : 'M/d/yy h:mma' }}
                      <ng-container *ngIf="item.hasError || item.hasWarning">
                        <div class="me-1 ms-1">-</div>
                          <div id="errorToggle" class="e2g-link" (click)="toggleExpanded($event, item)">
                            {{ getExpandText(item) }}
                          </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-nowrap align-items-center">
                  <fa-icon
                    *ngIf="item.hasFile"
                    [icon]="faDownload"
                    size="lg"
                    class="icon-secondary fa-fw me-1 ms-1"
                    (click)="download(item)"
                  ></fa-icon>
                  <fa-icon
                    *ngIf="!newOrInProgress(item.status)"
                    [icon]="faTrashXmark"
                    size="lg"
                    class="delete-notification pointer ms-2"
                    (click)="onDelete(item.source, item.id)"
                  >
                  </fa-icon>
                </div>
              </div>
              <div
                *ngIf="item.expanded"
                class="p-2"
                [messageId]="item.id"
                [jobSource]="item.source"
                app-notification-details
              ></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noNotifications>
  <div class="p-2" id="div-no-notifications">No notifications at this time</div>
</ng-template>
<ng-template #loading>
  <div class="loading" id="div-loading"></div>
</ng-template>
<ng-template #processingJob>
  <div class="spinner-icon" id="in-progress"></div>
</ng-template>
