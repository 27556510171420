<div class="ps-0 pe-0 me-4">
  <span class="column-label">{{ unselectedLabel }}</span
  ><span *ngIf="!readOnly"> - </span><a *ngIf="!readOnly" class="add-or-remove-all" (click)="addAll()">Add All</a>

  <div *ngFor="let item of unselected" class="flex-row">
    <e2g-check-input
      [heading]="item.label"
      [readonly]="readOnly"
      labelPosition="right"
      (change)="onSelectedOrUnselectedChange(item.value!, true)"
    >
    </e2g-check-input>
  </div>
</div>
<div>
  <span class="column-label">{{ selectedLabel }}</span
  ><span *ngIf="!readOnly"> - </span><a *ngIf="!readOnly" class="add-or-remove-all" (click)="removeAll()">Remove All</a>

  <div *ngFor="let item of selected" class="flex-row">
    <e2g-check-input
      [heading]="item.label"
      [readonly]="readOnly"
      labelPosition="right"
      [ngModel]="itemChecked"
      [disabled]="item.disabled ?? false"
      (change)="onSelectedOrUnselectedChange(item.value!, false)"
    >
    </e2g-check-input>
  </div>
</div>
