import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faDownload,
  faExclamationCircle,
  faTimesCircle,
  faTrashXmark,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { SageNavService } from 'src/app/shared-module/services/sage-nav.service';
import { FileDownloadHelper } from 'src/app/utilities/file-download-helper';

import { DialogComponent } from '../../shared-module/dialog/dialog.component';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { StateService } from '../../state.service';
import { JobSources } from '../models/job-sources';
import { getJobStatsText, JobStatus } from '../models/job-status';
import { JobStatusDto } from '../models/job-status-dto';
import { JobNotificationService } from '../services/job-notifications.service';

export interface JobStatusView extends JobStatusDto {
  statusText: string;
  expanded: boolean;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {
  public notifications: Observable<Array<JobStatusView>>;
  public jobStatus = JobStatus;
  public inProgress = JobStatus.InProgress;

  public faTrashXmark = faTrashXmark;
  public faDownload = faDownload;

  private expandedIds: Array<number> = [];

  public constructor(
    private jobNotificationsService: JobNotificationService,
    private stateService: StateService,
    private dialog: MatDialog,
    private sageNavService: SageNavService
  ) {
    this.notifications = jobNotificationsService.jobNotifications.pipe(
      map((data) =>
        data.map(
          (x) =>
            ({
              ...x,
              statusText: getJobStatsText(x.status),
              expanded: this.expandedIds.includes(x.id)
            } as JobStatusView)
        )
      )
    );
  }

  public onDelete(source: JobSources, id: number): void {
    this.jobNotificationsService
      .onDelete(source, id)
      .pipe(take(1))
      .subscribe((deleted) => {
        if (deleted) {
          this.jobNotificationsService.refreshNotifications();
        }
      });
  }

  //TODO Complete Unit test
  public onDeleteAll(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        dialogId: 'dlg-notification',
        title: 'Delete Notifications?',
        message: 'All completed notifications will be deleted.',
        buttons: DialogButtons.YesCancel,
        yesButtonText: 'Delete Notifications'
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result === DialogResult.yes),
        switchMap(() => this.jobNotificationsService.onDeleteAll()),
        filter((success) => success === true)
      )
      .subscribe(() => this.jobNotificationsService.refreshNotifications());
  }

  public getIcon(item: JobStatusView): IconProp {
    switch (item.status) {
      case JobStatus.Errored:
      case JobStatus.Cancelled:
        return faTimesCircle;
      case JobStatus.Complete:
        if (item.hasError) {
          return faTriangleExclamation;
        }
        if (item.hasWarning) {
          return faExclamationCircle;
        }

        return faCheckCircle;
      default:
        throw `JobStatus type not found or not configured: ${item.status}`;
    }
  }

  public getIconClass(item: JobStatusView): string {
    if (item.status === JobStatus.Errored || item.status === JobStatus.Cancelled || item.hasError) {
      return 'error';
    }
    if (item.status === JobStatus.Complete) {
      if (item.hasWarning) {
        return 'warning';
      }
      return 'success';
    }
    throw `JobStatus type not found or not configured: ${item.status}`;
  }

  public newOrInProgress(jobStatus: JobStatus): boolean {
    return jobStatus === JobStatus.New || jobStatus === JobStatus.InProgress;
  }

  public toggleExpanded(event: Event, item: JobStatusView): void {
    event.stopPropagation();
    item.expanded = !item.expanded;
    if (item.expanded) {
      this.expandedIds.push(item.id);
    } else {
      this.expandedIds = this.expandedIds.filter((x) => x !== item.id);
    }
  }

  public getExpandText(item: JobStatusView): string {
    const action = item.expanded ? 'Hide' : 'Show';
    const error = item.hasError ? 'Error' : 'Warning';
    return `${action} ${error}`;
  }

  public moduleStatusText(status: JobStatus): string {
    if (this.newOrInProgress(status)) {
      return 'Calculating';
    }
    if (status === JobStatus.Complete) {
      return 'Calculated';
    }
    if (status === JobStatus.Errored) {
      return "Couldn't Calculate";
    }

    throw new Error('JobStatus not configured for module status text');
  }

  public canDeleteAll(notifications: Array<JobStatusView>): boolean {
    return notifications.filter((x) => x.status !== JobStatus.New && x.status !== JobStatus.InProgress).length > 0;
  }

  public trackNotification(index: number, notification: JobStatusView): any {
    return notification.id;
  }

  public download(item: JobStatusView): void {
    this.jobNotificationsService.getJobFile(item.id).subscribe((resp) => {
      FileDownloadHelper.downloadFile(
        resp,
        `${item.name} ${FileDownloadHelper.getTimestampForFile(item.creationTime)}`
      );
    });
  }

  public goToLink(item: JobStatusView): void {
    this.sageNavService.navSageModuleFromRouteData(
      { moduleId: item.moduleId!, assetId: item.assetId, unitId: item.unitId, unitOfMeasure: item.unitOfMeasure },
      item.childIndex
    );
  }

  public onOutsideClick(target: HTMLElement): void {
    if (this.shouldClose(target)) {
      this.stateService.setNotificationsDisplay(false);
    }
  }

  private shouldClose(target: Element | null): boolean {
    if (!target) {
      return true;
    }

    if (target.id.includes('notification')) {
      return false;
    }

    return this.shouldClose(target.parentElement);
  }
}
