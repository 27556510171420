export const parseMarkdown = (msg: string): string => {
  return msg
    .replace(
      '[**Criteria Satisfied:**](Color=green)',
      '<span style="font-weight:bold; color: green">Criteria Satisfied</span>:'
    )
    .replace(
      '[**Criteria NOT Satisfied:**](Color=red)',
      '<span style="font-weight:bold; color: red">Criteria NOT Satisfied</span>:'
    )
    .replace('[**Warning:**](Color=orange)', '<span style="font-weight:bold; color: orange">Warning</span>:')
    .replace('[**Criteria NOT Satisfied:**](Color=red)', 'Criteria NOT Satisfied:');
};

export function formatBreadcrumbs(breadcrumbs: Array<string>): string {
  return breadcrumbs.join(' > ');
}

export function areStringsEqualCaseInsensitive(str1: string | undefined, str2: string | undefined): boolean {
  return (str1 ?? '').localeCompare(str2 ?? '', 'en', { sensitivity: 'base' }) === 0;
}

export function listContainsStringCaseInsensitive(list: Array<string>, str: string | undefined): boolean {
  return list.some((x) => areStringsEqualCaseInsensitive(x, str));
}
