import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, tap } from 'rxjs';
import { AssetDetailTabs } from 'src/app/asset-module/detail/detail.component';
import { AssetDesignTabs } from 'src/app/asset-module/models/enums/tabs/asset-details-sub-tabs';
import { BreadcrumbsService } from 'src/app/breadcrumb-module/breadcrumbs.service';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { AssetDetailsNavService } from 'src/app/shared-module/services/asset-details-nav.service';
import { RouteParamsService } from 'src/app/shared-module/services/route-params.service';
import { ModuleRouteData } from 'src/app/shared-module/services/sage-nav.service';
import { StateService } from 'src/app/state.service';

import { SageSavePacket } from '../../models/sage-save-packet';
import { SageUIConfig } from '../../models/sage-ui-config';
import { SageModuleDataService } from '../sage-module-data.service';
import { ISageModuleService } from '../sage-module-service';

@Injectable()
export class AssetModuleService implements ISageModuleService {
  private sageRouteData!: ModuleRouteData;
  private sageFolderIds: Array<string> = [];

  public constructor(
    private breadcrumbsService: BreadcrumbsService,
    private dataService: SageModuleDataService,
    private assetDetailsNavService: AssetDetailsNavService,
    private stateService: StateService,
    private paramsService: RouteParamsService
  ) {
    this.paramsService.getSageRouteData().subscribe((sageRouteData) => (this.sageRouteData = sageRouteData));
  }

  public getBreadcrumbs(name: string, moduleType: string): Observable<Breadcrumb[]> {
    return forkJoin([
      this.breadcrumbsService.getAssetBreadcrumbs(this.sageRouteData.assetId!).pipe(
        map((assetBcs) => {
          assetBcs[2].linkCommand = (): void => {
            this.stateService.setFlowForHeirarchyTree(assetBcs[0].id, assetBcs[1].id, assetBcs[2].id);
            this.goBack(AssetDesignTabs.Overview);
          };
          return assetBcs;
        })
      ),
      this.breadcrumbsService.getSageModuleBreadcrumbs(this.sageRouteData.moduleId).pipe(
        map((sageBcs) =>
          sageBcs.map((bc, index) => ({
            id: bc.id,
            name: bc.name,
            linkCommand: (): void => {
              this.stateService.setSageTreePath(sageBcs.map((x) => x.id!).slice(0, index + 1));
              this.goBack(AssetDesignTabs.Sage);
            }
          }))
        ),
        tap((sageBcs) => (this.sageFolderIds = sageBcs.map((x) => x.id!)))
      )
    ]).pipe(
      map(([assetBcs, moduleBcs]) => assetBcs.concat(moduleBcs).concat({ name: `${name} (${moduleType})` } as any))
    );
  }
  public save(sageSavePacket: SageSavePacket): Observable<boolean> {
    return this.dataService.saveData(this.sageRouteData.moduleId, sageSavePacket);
  }
  public getCalcStatus(): Observable<string> {
    return this.dataService.getModuleStatus(this.sageRouteData.moduleId);
  }

  public getSageUIConfig(): Observable<SageUIConfig> {
    return of({
      share: true,
      shareDisabledTooltip: 'Modules cannot be shared when attached to an asset',
      export: true,
      childModules: true,
      examples: true,
      parametric: true
    } as SageUIConfig);
  }

  public routeBack(): void {
    this.stateService.setSageTreePath([...this.sageFolderIds, this.sageRouteData.moduleId]);
    this.goBack(AssetDesignTabs.Sage);
  }

  private goBack(assetDesignTab: AssetDesignTabs): void {
    this.assetDetailsNavService.navAssetDetails(
      this.sageRouteData.unitId!,
      this.sageRouteData.assetId!,
      this.sageRouteData.unitOfMeasure!,
      AssetDetailTabs.Asset,
      assetDesignTab
    );
  }
}
