import { Injectable } from '@angular/core';
import { DialogButtons, DialogData, DialogResult } from '@equityeng/e2g-ng-ui';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { CmlDetailReportDialogComponent } from '../cml-detail-report-dialog/cml-detail-report-dialog.component';
import { AssetSummaryReportDto } from '../models/asset-summary-report-dto';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class AssetSummaryReportService {
  public constructor(
    private dialogService: DialogService,
    private dataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(): void {
    const formData: AssetSummaryReportDto = {
      assetIds: [],
      timeZoneOffset: new Date().getTimezoneOffset()
    };

    const reportName = 'Asset Summary Report'; //Also used as ID for notification replacement

    const dlgData: DialogData = {
      title: reportName,
      buttons: DialogButtons.YesCancel,
      component: CmlDetailReportDialogComponent,
      componentData: formData,
      yesButtonText: 'Save Report',
      width: '750px',
      height: '600px'
    };

    this.dialogService
      .display(dlgData)
      .pipe(
        filter((dlgResult) => dlgResult === DialogResult.yes),
        tap(() => this.notificationService.showActionStarting(ActionTypes.Generate, reportName, reportName)),
        switchMap(() => this.dataService.generateAssetSummaryReport(formData))
      )
      .subscribe((success) => {
        if (success) {
          this.jobNotificationsService.refreshNotifications();
        } else {
          //Only show error if Background process fails to start
          this.notificationService.showActionResult(success, ActionTypes.Generate, reportName, reportName);
        }
      });
  }
}
