import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DashboardFiltersDto } from './dashboard-module/models/dashboard-filters-dto';
import { DashboardSlideoutData } from './dashboard-module/models/dashboard-slideout-data';
import { HierarchyTreeFiltersDto } from './hierarchy-tree-module/models/hierarchy-tree-filters-dto';
import { SageTreeFolderConfiguration } from './sage-tree-module/sage-tree-folder-select/sage-tree-folder-selection';

interface SelectedGridIdData {
  grid: string;
  id?: string;
}

export interface State {
  notifications: {
    display: boolean;
    tooltip: string;
  };

  slideOut: {
    display: boolean;
    widthPercent: number;
  };

  flow: {
    corpKey?: string;
    plantKey?: string;
    unitKey?: string;
    assetKey?: string;
    componentKey?: string;
    activityKey?: string;
    activityHistoryKey?: string;
    findingKey?: string;
    dmlKey?: string;
    dashboardData?: DashboardSlideoutData;
    dashboardFilters?: DashboardFiltersDto;
    dashboardReloadSlideout?: boolean;
    activeTabId?: number;
    activityTypeName?: string;
  };

  sageTree: {
    isSelected?: boolean;
    path?: Array<string>;
  };

  treeFilter?: HierarchyTreeFiltersDto;

  selectedGridIds: Array<SelectedGridIdData>;

  sageTreeSlideoutConfiguration?: SageTreeFolderConfiguration;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public state: Observable<State>;

  private internalState: State = {
    notifications: {
      display: false,
      tooltip: 'Show Notifications'
    },
    slideOut: {
      display: false,
      widthPercent: 90
    },
    flow: {},
    sageTree: {},
    treeFilter: undefined,
    selectedGridIds: []
  };

  public constructor() {
    this.state = new BehaviorSubject(this.internalState);
  }

  public get staticState(): State {
    return { ...(this.state as BehaviorSubject<State>).value };
  }

  private updateState(): void {
    (this.state as BehaviorSubject<State>).next(this.internalState);
  }

  public setNotificationsDisplay(display: boolean): void {
    this.internalState = {
      ...this.internalState,
      notifications: {
        display: display,
        tooltip: display ? 'Hide Notifications' : 'Show Notifications'
      }
    };

    this.updateState();
  }

  public setSlideOutDisplay(display: boolean, widthPercent: number = 90): void {
    this.internalState = {
      ...this.internalState,
      slideOut: {
        display: display,
        widthPercent: widthPercent
      }
    };

    this.updateState();
  }

  public clearFlow(): void {
    this.internalState = {
      ...this.internalState,
      flow: {}
    };

    this.updateState();
  }

  public setFlowCorp(corpKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        corpKey: corpKey
      }
    };

    this.updateState();
  }

  public setFlowPlant(plantKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        plantKey: plantKey
      }
    };

    this.updateState();
  }

  public setFlowUnit(unitKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        unitKey: unitKey
      }
    };

    this.updateState();
  }

  public setFlowForUnitDetails(
    plantKey: string | undefined,
    unitKey: string | undefined,
    assetKey: string | undefined,
    activeTabId: number | undefined
  ): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        plantKey: plantKey,
        unitKey: unitKey,
        assetKey: assetKey,
        activeTabId: activeTabId
      }
    };

    this.updateState();
  }

  public setFlowAsset(assetKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        assetKey: assetKey
      }
    };

    this.updateState();
  }

  public setFlowComponent(componentKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        componentKey: componentKey
      }
    };

    this.updateState();
  }

  public setFlowForHeirarchyTree(
    plantKey: string | undefined,
    unitKey: string | undefined,
    assetKey: string | undefined
  ): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        plantKey: plantKey,
        unitKey: unitKey,
        assetKey: assetKey
      }
    };

    this.updateState();
  }

  public setFlowActivity(activityKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        activityKey: activityKey
      }
    };

    this.updateState();
  }

  public setFlowNewHistoryActivity(activityTypeName: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        activityTypeName: activityTypeName
      }
    };

    this.updateState();
  }

  public setFlowActivityHistory(activityKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        activityHistoryKey: activityKey
      }
    };

    this.updateState();
  }

  public setFlowFinding(findingKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        findingKey: findingKey
      }
    };

    this.updateState();
  }

  public setFlowDml(dmlKey: string | undefined): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        dmlKey: dmlKey
      }
    };

    this.updateState();
  }

  public setFlowDashboard(
    DashboardSlideoutData: DashboardSlideoutData | undefined,
    dashboardFilters: DashboardFiltersDto | undefined
  ): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        dashboardData: DashboardSlideoutData,
        dashboardFilters: dashboardFilters,
        dashboardReloadSlideout: false
      }
    };

    this.updateState();
  }

  public setDashboardReloadSlideout(reload: boolean): void {
    this.internalState = {
      ...this.internalState,
      flow: {
        ...this.internalState.flow,
        dashboardReloadSlideout: reload
      }
    };
    this.updateState();
  }

  public setSageTreePath(path: Array<string> | undefined): void {
    this.internalState = {
      ...this.internalState,
      sageTree: {
        ...this.internalState.sageTree,
        path: path ? [...path] : path
      }
    };

    this.updateState();
  }

  public clearSageTreePath(): void {
    this.internalState = {
      ...this.internalState,
      sageTree: {
        ...this.internalState.sageTree,
        path: undefined
      }
    };

    this.updateState();
  }

  public setIsSageTreeSelected(isSelected: boolean): void {
    this.internalState = {
      ...this.internalState,
      sageTree: {
        ...this.internalState.sageTree,
        isSelected
      }
    };

    this.updateState();
  }

  public setFilters(filters: HierarchyTreeFiltersDto): void {
    this.internalState = {
      ...this.internalState,
      treeFilter: filters
    };
  }

  public clearFilter(): void {
    this.internalState = {
      ...this.internalState,
      treeFilter: undefined
    };

    this.updateState();
  }

  public setSelectedGridId(grid: string, id?: string): void {
    this.internalState = {
      ...this.internalState,
      selectedGridIds: [
        ...this.internalState.selectedGridIds.filter((x) => x.grid !== grid),
        {
          grid,
          id
        }
      ]
    };

    this.updateState();
  }

  public clearSelectedGridData(): void {
    this.internalState = {
      ...this.internalState,
      selectedGridIds: []
    };

    this.updateState();
  }

  public getSelectedGridId(grid: string): string | undefined {
    return this.internalState.selectedGridIds.find((x) => x.grid === grid)?.id;
  }

  public getActiveTabId(): number | undefined {
    return this.internalState.flow.activeTabId;
  }

  public setSageTreeSlideoutConfiguration(sageTreeSlideoutConfiguration: SageTreeFolderConfiguration): void {
    this.internalState = {
      ...this.internalState,
      sageTreeSlideoutConfiguration
    };

    this.updateState();
  }
}
