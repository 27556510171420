import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';

import { ToastDisplayProperties } from './toast';

export class ErrorToast implements ToastDisplayProperties {
  public color = 'var(--system-bg-primary-error)';
  public showSpinner = false;
  public icon = faCircleXmark;
}

export class InfoToast implements ToastDisplayProperties {
  public color = 'var(--system-bg-primary-info)';
  public showSpinner = true;
}

export class SuccessToast implements ToastDisplayProperties {
  public color = 'var(--system-bg-primary-success)';
  public showSpinner = false;
  public icon = faCircleCheck;
}

export class WarningToast implements ToastDisplayProperties {
  public color = 'var(--system-bg-primary-danger)';
  public showSpinner = true;
  public closable: boolean = false;
}
