<div class="d-flex">
  <button
    id="btn-revert"
    class="btn e2g-btn-outline-primary me-2"
    (click)="vm.onRevert()"
    *ngIf="vm.showRevert"
    [disabled]="vm.revertDisabled"
  >
    <fa-icon [icon]="revertIcon" class="me-2"></fa-icon>
    Revert
  </button>
  <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="vm.onSave()" [disabled]="vm.saveDisabled">
    <fa-icon [icon]="saveIcon" class="me-2"></fa-icon>
    {{ vm.saveButtonText }}
  </button>
</div>
