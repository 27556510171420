import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronDown,
  faChevronRight,
  faDownload,
  faExclamationTriangle,
  faTrashXmark
} from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { FileDownloadHelper } from 'src/app/utilities/file-download-helper';

import { DialogComponent } from '../../shared-module/dialog/dialog.component';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { StateService } from '../../state.service';
import { JobSources } from '../models/job-sources';
import { getJobStatsText, JobStatus } from '../models/job-status';
import { JobStatusDto } from '../models/job-status-dto';
import { JobNotificationService } from '../services/job-notifications.service';

interface JobStatusView extends JobStatusDto {
  statusText: string;
  expanded: boolean;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {
  public notifications: Observable<Array<JobStatusView>>;
  public jobStatus = JobStatus;
  public inProgress = JobStatus.InProgress;

  public faTrashXmark = faTrashXmark;
  public faExclamationTriangle = faExclamationTriangle;
  public faTimesCircle = faTimesCircle;
  public faDownload = faDownload;

  private expandedIds: Array<number> = [];

  public constructor(
    private jobNotificationsService: JobNotificationService,
    private stateService: StateService,
    private dialog: MatDialog
  ) {
    this.notifications = jobNotificationsService.jobNotifications.pipe(
      map((data) =>
        data.map(
          (x) =>
            ({
              ...x,
              statusText: getJobStatsText(x.status),
              expanded: this.expandedIds.includes(x.id)
            } as JobStatusView)
        )
      )
    );
  }

  public onDelete(source: JobSources, id: number): void {
    this.jobNotificationsService
      .onDelete(source, id)
      .pipe(take(1))
      .subscribe((deleted) => {
        if (deleted) {
          this.jobNotificationsService.refreshNotifications();
        }
      });
  }

  //TODO Complete Unit test
  public onDeleteAll(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        dialogId: 'dlg-notification',
        title: 'Delete Notifications?',
        message: 'All completed notifications will be deleted.',
        buttons: DialogButtons.YesCancel,
        yesButtonText: 'Delete Notifications'
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result === DialogResult.yes),
        switchMap(() => this.jobNotificationsService.onDeleteAll()),
        filter((success) => success === true)
      )
      .subscribe(() => this.jobNotificationsService.refreshNotifications());
  }

  public getIcon(item: JobStatusView): IconProp {
    return item.expanded ? faChevronDown : faChevronRight;
  }

  public toggleExpanded(event: Event, item: JobStatusView): void {
    event.stopPropagation();
    item.expanded = !item.expanded;
    if (item.expanded) {
      this.expandedIds.push(item.id);
    } else {
      this.expandedIds = this.expandedIds.filter((x) => x !== item.id);
    }
  }

  public canDeleteAll(notifications: Array<JobStatusView>): boolean {
    return notifications.filter((x) => x.status !== JobStatus.New && x.status !== JobStatus.InProgress).length > 0;
  }

  public getProgressStyle(percent: number): string {
    return `height:10px;width:${percent}%`;
  }

  public trackNotification(index: number, notification: JobStatusView): any {
    return notification.id;
  }

  public download(item: JobStatusView): void {
    this.jobNotificationsService.getJobFile(item.id).subscribe((resp) => {
      FileDownloadHelper.downloadFile(
        resp,
        `${item.name} ${FileDownloadHelper.getTimestampForFile(item.creationTime)}`
      );
    });
  }

  public onOutsideClick(target: HTMLElement): void {
    if (this.shouldClose(target)) {
      this.stateService.setNotificationsDisplay(false);
    }
  }

  private shouldClose(target: Element | null): boolean {
    if (!target) {
      return true;
    }

    if (target.id.includes('notification')) {
      return false;
    }

    return this.shouldClose(target.parentElement);
  }
}
