import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModuleCalculationResult, UnitSystem } from '@equityeng/sage-forms-core';
import { IFunctionalDefaults, sageCall, sageFunctionCallResult } from '@equityeng/sage-forms-core/functional-defaults';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';
import { ModuleRouteData } from 'src/app/shared-module/services/sage-nav.service';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { NameAndId } from '../../models/name-and-id';
import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { CalcSageModuleRequest } from '../models/calculations/calc-sage-module-request';
import { ExportInpFileDto } from '../models/export-inp-file-dto';
import { ExportOutFileDto } from '../models/export-out-file-dto';
import { SageNodeDataScra } from '../models/module-node';
import { SageInputFileType } from '../models/sage-import-file-type';
import { SageModuleInfo } from '../models/sage-module-info-dto';
import { SageSavePacket } from '../models/sage-save-packet';
import { ShareUserDto } from '../models/share-user-dto';

@Injectable({
  providedIn: 'root'
})
export class SageModuleDataService implements IFunctionalDefaults {
  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private jobNotificationService: JobNotificationService
  ) {}

  public getData(moduleRouteData: ModuleRouteData): Observable<SageNodeDataScra> {
    if (moduleRouteData.prd) {
      return this.http.get<SageNodeDataScra>(
        `${this.apiUrl}/sage/${moduleRouteData.moduleId}/prd/${moduleRouteData.assetId}/${
          UnitOfMeasure[moduleRouteData.unitOfMeasure as keyof typeof UnitOfMeasure]
        }`
      );
    }
    return this.http.get<SageNodeDataScra>(`${this.apiUrl}/sage/${moduleRouteData.moduleId}`);
  }

  private get apiUrl(): string {
    return this.settingsService.settings.apiUri ?? '';
  }

  public getOutput(id: string): Observable<ModuleCalculationResult> {
    return this.http.get<ModuleCalculationResult>(`${this.apiUrl}/sage/output/${id}`);
  }

  public saveData(id: string, packet: SageSavePacket): Observable<boolean> {
    return this.http.put<void>(`${this.apiUrl}/sage/${id}`, packet).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public saveComponentModule(moduleId: string, componentId: string, packet: SageSavePacket): Observable<boolean> {
    return this.http.put<void>(`${this.apiUrl}/sage/${moduleId}/${componentId}`, packet).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public savePrdModule(moduleId: string, assetId: string, packet: SageSavePacket): Observable<boolean> {
    return this.http.put<void>(`${this.apiUrl}/sage/${moduleId}/prd/${assetId}`, packet).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public calculate(id: string, version: string): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/calculations/sage/${id}/version/${version}`, {}).pipe(
      catchError(() => of(-1)),
      tap(() => {
        this.jobNotificationService.refreshNotifications();
      })
    );
  }

  public calculateSageModule(calcReq: CalcSageModuleRequest): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/calculations/sage-module`, calcReq).pipe(
      catchError(() => of(-1)),
      tap(() => {
        this.jobNotificationService.refreshNotifications();
      })
    );
  }

  public getDefaults(
    module: string,
    version: string,
    defaultCalls: sageCall[],
    validValueCalls: sageCall[],
    unitSystem: UnitSystem
  ): Observable<sageFunctionCallResult> {
    const request = {
      module: module,
      defaults: defaultCalls,
      validValues: validValueCalls,
      unitSystem: unitSystem
    };
    return this.http.post<sageFunctionCallResult>(`${this.apiUrl}/SageFunctions/${version}/CallFunctions`, request);
  }

  public getSageModuleList(): Observable<Array<SageModuleInfo>> {
    return this.http
      .get<Array<SageModuleInfo>>(`${this.apiUrl}/sage/catalog`)
      .pipe(catchError(() => of(new Array<SageModuleInfo>())));
  }

  public getModuleBreadcrumbs(id: string): Observable<Array<NameAndId>> {
    return this.http
      .get<Array<NameAndId>>(`${this.apiUrl}/sage/${id}/breadcrumbs`)
      .pipe(catchError(() => of(new Array<NameAndId>())));
  }

  public getModuleStatus(id: string): Observable<string> {
    return this.http.get<any>(`${this.apiUrl}/sage/${id}/status`).pipe(
      map((x) => x.status!),
      catchError(() => of(''))
    );
  }

  public getModuleExampleCases(moduleName: string): Observable<Array<string>> {
    return this.http
      .get<Array<string>>(`${this.apiUrl}/sage/modules/${moduleName}`)
      .pipe(catchError(() => of(new Array<string>())));
  }

  public useExampleCase(moduleId: string, exampleCase: string, moduleName: string): Observable<boolean> {
    return this.http
      .post(`${this.apiUrl}/sage/modules/${moduleId}/example/${exampleCase}`, JSON.stringify(moduleName))
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public exportModuleIn(id: string, fileType: SageInputFileType): Observable<ExportInpFileDto> {
    return this.http.get<ExportInpFileDto>(`${this.apiUrl}/sage/${id}/export/in/${fileType}`);
  }

  public exportModuleOut(id: string): Observable<ExportOutFileDto> {
    return this.http.get<ExportOutFileDto>(`${this.apiUrl}/sage/${id}/export/out`);
  }

  public getShareUsers(): Observable<Array<ShareUserDto>> {
    return this.http.get<Array<ShareUserDto>>(`${this.apiUrl}/authorization/users`);
  }

  public getSharedWithUsers(id: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${this.apiUrl}/sage/${id}/shared-with`);
  }

  public updateSharedWithUsers(id: string, sharedWith: Array<string>): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/sage/${id}/shared-with`, sharedWith);
  }
}
