import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { AuthService, User } from '@equityeng/auth';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PRODUCT_NAME } from '../models/auth-constants';

export function hasFeatureGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const authService: AuthService = inject(AuthService);
    const feature: string | undefined = route.data?.['feature'];
    if (feature) {
      return authService.userAuthenticated$.pipe(map((user: User) => user.hasAccessToFeature(PRODUCT_NAME, feature)));
    }
    return of(false);
  };
}