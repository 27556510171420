import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { map, switchMap } from 'rxjs/operators';

import { CompanyService } from '../company.service';
import { PRODUCT_NAME } from '../models/auth-constants';

export function hasAnyFeatureOrPermission(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const authService: AuthService = inject(AuthService);
    const companyService: CompanyService = inject(CompanyService);
    const features: Array<string> = route.data?.features || [];
    const permissions: Array<string> = route.data?.permissions || [];

    return companyService.selectedCompany.pipe(
      switchMap(() =>
        authService.userAuthenticated$.pipe(
          map((user) =>
            [
              ...features.map((f) => user.hasAccessToFeature(PRODUCT_NAME, f)),
              ...permissions.map((p) => user.hasPermission(p))
            ].some((x) => x === true)
          )
        )
      )
    );
  };
}
