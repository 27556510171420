import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of, tap } from 'rxjs';
import { BreadcrumbsService } from 'src/app/breadcrumb-module/breadcrumbs.service';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { RouteParamsService } from 'src/app/shared-module/services/route-params.service';
import { ModuleRouteData } from 'src/app/shared-module/services/sage-nav.service';
import { StateService } from 'src/app/state.service';

import { SageSavePacket } from '../../models/sage-save-packet';
import { SageUIConfig } from '../../models/sage-ui-config';
import { SageModuleDataService } from '../sage-module-data.service';
import { ISageModuleService } from '../sage-module-service';

@Injectable()
export class UserModuleService implements ISageModuleService {
  private sageRouteData!: ModuleRouteData;
  private sageFolderIds: Array<string> = [];
  public constructor(
    private breadcrumbsService: BreadcrumbsService,
    private dataService: SageModuleDataService,
    private router: Router,
    private paramsService: RouteParamsService,
    private stateService: StateService
  ) {
    this.paramsService.getSageRouteData().subscribe((sageRouteData) => (this.sageRouteData = sageRouteData));
  }

  public getBreadcrumbs(name: string, moduleType: string): Observable<Breadcrumb[]> {
    return this.breadcrumbsService.getSageModuleBreadcrumbs(this.sageRouteData.moduleId).pipe(
      tap((breadcrumbs) => (this.sageFolderIds = breadcrumbs.map((x) => x.id!))),
      map((breadcrumbs) => breadcrumbs.concat({ name: `${name} (${moduleType})` }))
    );
  }

  public save(sageSavePacket: SageSavePacket): Observable<boolean> {
    return this.dataService.saveData(this.sageRouteData.moduleId, sageSavePacket);
  }
  public getCalcStatus(): Observable<string> {
    return this.dataService.getModuleStatus(this.sageRouteData.moduleId);
  }

  public getSageUIConfig(): Observable<SageUIConfig> {
    return of({
      share: true,
      shareDisabledTooltip: 'Sharing is not allowed for modules under folders',
      export: true,
      childModules: true,
      examples: true,
      parametric: true
    } as SageUIConfig);
  }

  public routeBack(): void {
    this.stateService.setSageTreePath([...this.sageFolderIds, this.sageRouteData.moduleId]);
    this.router.navigate(['sage']);
  }
}
