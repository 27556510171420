<div class="d-flex justify-content-between align-items-center ps-4 pe-4 slideout-footer">
  <div class="d-flex align-items-center">
    <button id="btn-cancel" class="btn e2g-btn-outline-primary" (click)="onCancel()">Close</button>
    <div *ngIf="extraLeftItems as leftItems" class="d-flex flex-row align-items-center ms-2">
      <ng-container *ngFor="let template of leftItems">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex align-items-center">
    <div *ngIf="extraCenterItems as centerItems" class="d-flex flex-row align-items-center">
      <ng-container *ngFor="let template of centerItems">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex align-items-center">
    <div *ngIf="showExtraRightItems && extraRightItems as rightItems" class="d-flex flex-row align-items-center me-2">
      <ng-container *ngFor="let template of rightItems">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
    <app-save-revert *ngIf="this.showSave | async" [vm]="saveRevertVm"></app-save-revert>
  </div>
</div>
