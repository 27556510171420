import { Component, OnInit } from '@angular/core';
import { DialogData, E2gSelectOption, IDialogFormData } from '@equityeng/e2g-ng-ui';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AssetTypes } from 'src/app/models/enums/asset-types';

import { CompanyService } from '../../company.service';
import { EquipmentDataService } from '../../equipment-data.service';
import { EquipmentDto } from '../../models/equipment-dto';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { PlantDataService } from '../../plant-data.service';
import { UnitDataService } from '../../unit-data.service';
import { CmlDetailReportDto } from '../models/cml-detail-report-dto';

@Component({
  selector: 'app-cml-detail-report-dialog',
  templateUrl: './cml-detail-report-dialog.component.html',
  styleUrls: ['./cml-detail-report-dialog.component.css'],
  providers: [
    {
      provide: IDialogFormData,
      useExisting: CmlDetailReportDialogComponent
    }
  ]
})
export class CmlDetailReportDialogComponent extends OnDestroyBaseComponent implements OnInit, IDialogFormData {
  public dialogData!: DialogData;
  public formData!: CmlDetailReportDto;

  public plantErrors = [] as string[];
  public plantList?: Array<E2gSelectOption> = [];
  public unitList?: Array<E2gSelectOption> = [];
  public equipmentList?: Array<EquipmentDto> = [];
  public equipmentOptions: Array<E2gSelectOption> = [];

  public selectedPlant?: string;
  public selectedUnitIds: Array<string> = [];
  public pastUnitIds: Array<string> = [];

  public dropDownSelectUnit: boolean = false;
  public dropDownSelectEquipment: boolean = false;
  public displayLimit = 3;

  public constructor(
    private companyService: CompanyService,
    private plantService: PlantDataService,
    private unitService: UnitDataService,
    private equipmentService: EquipmentDataService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.dialogData.validityCallback = this.isValid;

    this.companyService.selectedCompany
      .pipe(
        switchMap(() => this.plantService.getPlants()),
        takeUntil(this.destroy)
      )
      .subscribe((plants) => {
        this.plantList = plants.map(
          (x) => ({ value: x.id, label: x.name } as E2gSelectOption)
        ) as Array<E2gSelectOption>;
      });
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public setFormData(formData: CmlDetailReportDto): void {
    this.formData = formData;
  }

  public onChangePlant(): void {
    this.unitList = undefined;
    this.unitService.getUnits(this.selectedPlant!).subscribe((units) => {
      this.equipmentList = [] as Array<EquipmentDto>;
      this.formData.assetIds = [];

      this.selectedUnitIds = [];
      this.pastUnitIds = [];
      this.unitList = units.map((x) => ({ label: x.name, value: x.id } as E2gSelectOption));
    });
  }

  public onUnitChange(): void {
    this.equipmentList = undefined;

    if (this.pastUnitIds.length !== this.selectedUnitIds.length) {
      this.pastUnitIds = this.selectedUnitIds;
      this.equipmentService.getEquipmentForUnitIds(this.selectedUnitIds).subscribe((equipment) => {
        this.equipmentList = equipment.filter((x) => x.type !== AssetTypes.PressureReliefDevice);
        this.equipmentOptions = this.equipmentList.map((x) => ({ label: x.name, value: x.id } as E2gSelectOption));
        this.formData.assetIds = [];
      });
    }
  }

  private isValid = (): boolean => {
    return this.formData.assetIds.length > 0;
  };
}
