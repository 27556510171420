import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@equityeng/auth';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { hasAnyFeatureOrPermission } from './guards/has-any-feature-or-permission.guard';
import { hasFeatureGuard } from './guards/has-feature.guard';
import { ShellComponent } from './home/shell/shell.component';
import { LandingTargetComponent } from './landing-target.component';
import {
  ADMIN,
  CAN_IMPORT,
  CAN_MANAGE_INSPECTION_ACTIVITIES,
  CAN_MANAGE_INSPECTIOR_LIST,
  CAN_MANAGE_ROLES,
  COMPANY_ADMIN
} from './models/auth-constants';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AssetModuleService } from './sage-wrapper-module/services/asset/asset-module.service';
import { RbiModuleService } from './sage-wrapper-module/services/rbi/rbi-module.service';
import { SAGE_MODULE_SERVICE } from './sage-wrapper-module/services/sage-module-service';
import { UserModuleService } from './sage-wrapper-module/services/user/user-module.service';
import { PrdRbiModuleService } from './sage-wrapper-module/services/rbi/prd-rbi-module.service';

const routes: Routes = [
  { path: '', redirectTo: 'target-location', pathMatch: 'full' },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'target-location', component: LandingTargetComponent },
      {
        path: 'assets',
        loadChildren: () => import('./hierarchy-tree-module/hierarchy-tree.module').then((m) => m.HierarchyTreeModule)
      },
      {
        path: 'assets/sage/expertview',
        loadChildren: () => import('./sage-wrapper-module/sage-wrapper.module').then((m) => m.SageWrapperModule),
        providers: [
          {
            provide: SAGE_MODULE_SERVICE,
            useClass: RbiModuleService
          }
        ]
      },
      {
        path: 'assets/sage/prdrbi',
        loadChildren: () => import('./sage-wrapper-module/sage-wrapper.module').then((m) => m.SageWrapperModule),
        providers: [
          {
            provide: SAGE_MODULE_SERVICE,
            useClass: PrdRbiModuleService
          }
        ]
      },
      {
        path: 'assets/detail',
        loadChildren: () => import('./asset-module/asset.module').then((m) => m.AssetModule)
      },
      {
        path: 'assets/sage/module',
        loadChildren: () => import('./sage-wrapper-module/sage-wrapper.module').then((m) => m.SageWrapperModule),
        providers: [
          {
            provide: SAGE_MODULE_SERVICE,
            useClass: AssetModuleService
          }
        ]
      },
      {
        path: 'assets/unit/details',
        loadChildren: () => import('./assets/unit-details-module/unit-details.module').then((m) => m.UnitDetailsModule)
      },
      {
        path: 'sage/module',
        loadChildren: () => import('./sage-wrapper-module/sage-wrapper.module').then((m) => m.SageWrapperModule),
        providers: [
          {
            provide: SAGE_MODULE_SERVICE,
            useClass: UserModuleService
          }
        ]
      },
      {
        path: 'sage',
        loadChildren: () => import('./sage-tree-module/sage-tree.module').then((m) => m.SageTreeModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings-module/settings.module').then((m) => m.SettingsModule),
        canActivate: [hasAnyFeatureOrPermission],
        data: {
          features: [ADMIN, COMPANY_ADMIN],
          permissions: [CAN_MANAGE_ROLES, CAN_MANAGE_INSPECTIOR_LIST, CAN_MANAGE_INSPECTION_ACTIVITIES]
        }
      },
      {
        path: 'stale-calcs',
        loadChildren: () => import('./stale-calcs-module/stale-calcs.module').then((m) => m.StaleCalcsModule),
        canActivate: [hasAnyFeatureOrPermission],
        data: {
          features: [ADMIN, COMPANY_ADMIN],
          permissions: [CAN_MANAGE_ROLES, CAN_MANAGE_INSPECTIOR_LIST, CAN_MANAGE_INSPECTION_ACTIVITIES]
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin-module/admin.module').then((m) => m.AdminModule),
        canActivate: [hasAnyFeatureOrPermission],
        data: {
          features: [ADMIN]
        }
      },
      {
        path: 'release-notes',
        loadChildren: () => import('./release-notes-module/release-notes.module').then((m) => m.ReleaseNotesModule)
      },
      {
        path: 'import',
        loadChildren: () => import('./import-module/import.module').then((m) => m.ImportModule),
        canActivate: [hasAnyFeatureOrPermission],
        data: {
          permissions: [CAN_IMPORT]
        }
      },
      {
        path: 'activity',
        loadChildren: () =>
          import('./activity-details-module/activity-details.module').then((m) => m.ActivityDetailsModule),
        canActivate: [hasFeatureGuard],
        data: { feature: COMPANY_ADMIN }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard-module/dashboard.module').then((m) => m.DashboardModule)
      }
    ]
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'auth-error', component: AuthErrorComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
