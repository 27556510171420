<button
  id="btn-select"
  class="btn e2g-btn-outline-primary me-2 button-text"
  mat-button
  [matMenuTriggerFor]="buttonMenu"
  *ngIf="canEdit"
  [disabled]="disabled"
>
  <fa-icon *ngIf="icon" id="icon" [icon]="icon" class="me-2"></fa-icon>{{ text
  }}<fa-icon id="icon-chevron-down" name="chevron-down" [icon]="faChevronDown" class="ms-2"></fa-icon>
</button>
<mat-menu #buttonMenu="matMenu" yPosition="below">
  <button mat-menu-item *ngFor="let item of options" (click)="itemClick(item)">
    <span class="button-item">{{ item.label }}</span>
  </button>
</mat-menu>
